import React from "react";
import { Link } from "gatsby";
import { FaRegHeart, FaHeart } from "react-icons/fa";

import { useWishList } from "@simplur/gatsby-theme-full-site/src/hooks";

const ProductWishlist = ({ product }) => {
  const { wishList, addOrRemove, userId } = useWishList();
  const isAdded = wishList.find((p) => p.slug === product.slug);

  return (
    <div className={`wishlist__button`}>
      {userId ? (
        isAdded ? (
          <FaHeart onClick={() => addOrRemove(product)} />
        ) : (
          <FaRegHeart onClick={() => addOrRemove(product)} />
        )
      ) : (
        <Link to="/login">
          <FaRegHeart />
        </Link>
      )}
    </div>
  );
};

export default ProductWishlist;
