import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";

import SubmitButton from "@simplur/gatsby-theme-full-site/src/components/SubmitButton";
import {
  useNoCodeApi,
  useThemeOption,
} from "@simplur/gatsby-theme-full-site/src/hooks";

const CardModal = ({ className, product }) => {
  const [submitting, setSubmitting] = useState(false);
  const [modal, setModal] = useState(false);
  const [nextStep, setNextStep] = useState(false);
  const [formData, setFormData] = useState({});
  const [message, setMessage] = useState("");
  const {
    noCodeApi: { RFPId },
  } = useThemeOption();
  const { handler } = useNoCodeApi(RFPId, "Contact");

  const toggle = () =>
    setModal((prevModal) => {
      if (prevModal) {
        setNextStep(false);
        setMessage("");
      }
      return !prevModal;
    });

  const handleInput = (e) => {
    const copyFormData = { ...formData };
    copyFormData[e.target.name] = e.target.value;
    setFormData(copyFormData);
  };

  const sendData = async (e) => {
    e.preventDefault();

    if (typeof fbq === `function`) {
      window.fbq("track", "Lead", {
        content_name: product.name,
        currency: "USD",
        value: 0,
      });
    }

    try {
      setSubmitting(true);

      const data = JSON.stringify([
        [
          formData.firstName,
          formData.lastName,
          formData.email,
          formData.phone,
          formData.city,
          formData.state,
          product.name,
          formData.message,
        ],
      ]);

      const json = await handler(data);

      setSubmitting(false);
      setNextStep(true);
      if (json.error) {
        throw new Error(json.error);
      } else {
        setMessage(json.message);
      }
    } catch (error) {
      console.error("Error:", error);
      setMessage("Error");
      //setNextStep(false)
      setSubmitting(false);
    }
  };

  return (
    <div>
      <div className="text-center">
        <Button color="cta" onClick={toggle}>
          Request Information
        </Button>
      </div>
      <Modal size="lg" isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>Contact Me: {product?.name}</ModalHeader>
        <Container>
          <Row className="my-4">
            {!nextStep && (
              <Col sm="4">
                <p>
                  Fill out this form and have one of our knowledgable staff
                  members contact you about pricing, financing, and other
                  information for this product.
                </p>
              </Col>
            )}
            <Col>
              {(!nextStep && (
                <Form
                  name="Pricing Request"
                  onSubmit={sendData}
                  id="pricingRequest"
                >
                  <Row form>
                    <Col md="6">
                      <FormGroup>
                        <Label for="First Name">First Name</Label>
                        <Input
                          type="text"
                          name="firstName"
                          id="firstName"
                          required
                          onChange={handleInput}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label for="Last Name">Last Name</Label>
                        <Input
                          type="text"
                          name="lastName"
                          id="lastName"
                          onChange={handleInput}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Label for="exampleEmail">Email</Label>
                    <Input
                      type="email"
                      name="email"
                      id="Email"
                      onChange={handleInput}
                    />
                    <FormGroup>
                      <Label for="Phone Number">Phone Number (Required)</Label>
                      <Input
                        type="text"
                        name="phone"
                        id="phone"
                        required
                        onChange={handleInput}
                      />
                    </FormGroup>
                  </FormGroup>
                  <FormGroup>
                    <Label for="City">City</Label>
                    <Input
                      type="text"
                      name="city"
                      id="city"
                      onChange={handleInput}
                    />
                    <FormGroup>
                      <Label for="State">State</Label>
                      <Input
                        type="text"
                        name="state"
                        id="state"
                        required
                        onChange={handleInput}
                      />
                    </FormGroup>
                  </FormGroup>
                  <FormGroup>
                    <Label for="Your Message">Your Message</Label>
                    <Input
                      type="textarea"
                      name="message"
                      id="message"
                      onChange={handleInput}
                    />
                  </FormGroup>
                  <SubmitButton color="primary" submitting={submitting}>
                    Request Information
                  </SubmitButton>
                  <div>{message}</div>
                </Form>
              )) || (
                <>
                  {message.startsWith("Error") ? (
                    <div>
                      <p style={{ color: "red" }}>
                        Oops. Something went wrong with your submission.
                      </p>
                      <p>
                        Please call us at 1-800-865-9664 for help with your
                        request.
                      </p>
                    </div>
                  ) : (
                    <div>
                      <p style={{ color: "green" }}>
                        Your request has been submitted successfully.
                      </p>
                      <p>We will contact you soon</p>
                    </div>
                  )}
                  <Button color="primary" onClick={toggle}>
                    Close
                  </Button>
                </>
              )}
            </Col>
          </Row>
        </Container>
      </Modal>
    </div>
  );
};

export default CardModal;
